import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';

import Drawing from '../Drawing/Drawing';
import { theme } from '../common/theme';

const TextLogo = styled(Box)`
  min-width: 150px;
`;

const SplashHeader = ({ color }) => (
  <Flex justifyContent="space-between" alignItems="center" mb={[8]}>
    <TextLogo width={['150px', '200px']}>
      <Drawing name="textlogo" color={color} />
    </TextLogo>
    <Box width={['50px', '65px']}>
      <Drawing name="logo" color={color} />
    </Box>
  </Flex>
);

SplashHeader.propTypes = {
  /** Color of the logo elements */
  color: PropTypes.oneOf(Object.keys(theme.colors)),
};

SplashHeader.defaultProps = {
  mb: [],
  color: 'baseBlack',
};

export default SplashHeader;
