import React from 'react';
import ShowAt from '../Responsive/ShowAt';
import SplashSilo from '../SplashSilo';

const SplashSiloWrapper = props => (
  <>
    <ShowAt breakpoint="mediumAndBelow">
      <SplashSilo isMobile {...props} />
    </ShowAt>
    <ShowAt breakpoint="large">
      <SplashSilo {...props} />
    </ShowAt>
  </>
);

export default SplashSiloWrapper;
