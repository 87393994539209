import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box } from 'rebass';
import { theme } from '../common/theme';
import { siloColorScheme } from '../Silo/common';
import SiloLinkList from '../Silo/SiloLinkList';
import SplashHeader from '../SplashHeader';
import { Container, Column, Row } from '../../components/Grid';

const Wrapper = styled(Box)`
  transition: background-color ${theme.speed.default} ease;
`;

const SplashSilo = ({ silos, isMobile }) => {
  const [color, setColor] = useState('white');
  const colors = siloColorScheme[color] || siloColorScheme['white'];
  const { bg, logoColor } = colors;
  return (
    <>
      <Wrapper data-testid="silo-wrapper" className="silo-wrapper" bg={bg}>
        <Container>
          <Row>
            <Column py={[4, 5]}>
              <SplashHeader color={logoColor} />
              <SiloLinkList
                silos={silos}
                setColor={setColor}
                colors={colors}
                isMobile={isMobile}
                shouldAnimate={false}
              />
            </Column>
          </Row>
        </Container>
      </Wrapper>
    </>
  );
};

SplashSilo.propTypes = {
  /** The silos to render */
  silos: PropTypes.array,
  /** Is this the mobile view? */
  isMobile: PropTypes.bool,
  /** Transition status */
  transitionStatus: PropTypes.string,
  /** Should the Nav animate? */
  shouldAnimate: PropTypes.bool,
};

SplashSilo.defaultProps = {
  silos: [],
  isMobile: false,
  transitionStatus: '',
  shouldAnimate: false,
};

export default SplashSilo;
